/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { RiMoonClearLine } from "react-icons/ri";
import { GiBigDiamondRing, GiHeartTower, GiHeartInside } from "react-icons/gi";
import { RiUserHeartLine, RiHeartsLine } from "react-icons/ri";
// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { useHistory } from "react-router-dom";

import { useAuthenticator } from "@aws-amplify/ui-react";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const history = useHistory();

  const classes = useStyles();

  const { user, signOut } = useAuthenticator((context) => [context.user]);

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="The Big Day"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={<GiHeartTower />}
          dropdownList={[
            <Link to="/venue" className={classes.dropdownLink}>
              Our Venue
            </Link>,
            <Link to="/accommodation" className={classes.dropdownLink}>
              Accommodation
            </Link>,
            <Link to="/timeline" className={classes.dropdownLink}>
              Our Timeline
            </Link>,
            <Link to="/details" className={classes.dropdownLink}>
              Essential Information
            </Link>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="About Us"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={<RiHeartsLine />}
          dropdownList={[
            <Link to="/ourstory" className={classes.dropdownLink}>
              Our Story
            </Link>,
            <Link to="/meettheweddingparty" className={classes.dropdownLink}>
              Meet the Wedding Party
            </Link>,
            <Link to="/planning" className={classes.dropdownLink}>
              The Journey so Far
            </Link>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Make a Weekend of it"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={<GiHeartInside />}
          dropdownList={[
            <Link to="/thingstodo" className={classes.dropdownLink}>
              Things to Do
            </Link>,
            <Link to="/wheretoeat" className={classes.dropdownLink}>
              Where to Eat
            </Link>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText={user.username}
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={<RiUserHeartLine />}
          dropdownList={[
            <Link to="/profile" className={classes.dropdownLink}>
              Attendance
            </Link>,
            <Link to="/contactus" className={classes.dropdownLink}>
              Contact Us
            </Link>,
            <Link to="/" onClick={signOut} className={classes.dropdownLink}>
              Sign Out
            </Link>,
          ]}
        />
      </ListItem>
    </List>
  );
}
