import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import PropTypes from "prop-types";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
const useStyles = makeStyles(styles);

export default function PageHeader(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Parallax filter image={props.image}>
        <div className={classes.container}>
          <h1>{props.headerText}</h1>
        </div>
      </Parallax>
    </div>
  );
}

PageHeader.propTypes = {
  headerText: PropTypes.string,
  image: PropTypes.string,
};
