import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Parallax from "components/Parallax/Parallax.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import PropTypes from "prop-types";
const useStyles = makeStyles(styles);

export default function SomethingWentWrongPage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <Parallax filter>
        <div className={classes.container}>
          <h1>Oops!.... Something went wrong!</h1>
          <p>Please contact us if refreshing doesn't fix it!</p>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <br />
          <p className={classes.content}>Error: {props.error}</p>
          <br />
        </div>
      </div>
    </div>
  );
}

SomethingWentWrongPage.propTypes = {
  error: PropTypes.string,
};
