import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../Card/Card.js";
import CardBody from "../Card/CardBody.js";
import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";
import CardHeader from "../Card/CardHeader.js";
import PropTypes from "prop-types";
const useStyles = makeStyles(styles);

export default function IntroCard(props) {
  const classes = useStyles();
  const { headerText, children, ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <Card>
      <CardHeader color="primary" className={classes.cardHeader}>
        <h1>{headerText}</h1>
      </CardHeader>
      <CardBody>
        {/* <p>{props.bodyText}</p> */}
        {children}
      </CardBody>
    </Card>
  );
}

IntroCard.propTypes = {
  headerText: PropTypes.string,
  //bodyText: PropTypes.string,
  children: PropTypes.node,
};
