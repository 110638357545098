import {
  Authenticator,
  useAuthenticator,
  useTheme,
  View,
  Image,
  Heading,
  Button,
  Flex,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
//import Routes from "./Routes.js";
import AfterWeddingRoutes from "./AfterWeddingRoutes.js";
import { Amplify, I18n } from "aws-amplify";
import React from "react";
import awsExports from "./aws-exports";
import AuthLogo from "assets/img/authLogo2.svg";
//import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary.js";

Amplify.configure({
  ...awsExports,
  Analytics: {
    disabled: true,
  },
});

import "assets/css/AmplifyStyle.css";

I18n.putVocabulariesForLanguage("en", {
  "Account recovery requires verified contact information":
    "Please verify your e-mail",
  "Cannot reset password for the user as there is no registered/verified email or phone_number":
    "Sorry! We don't have a verified e-mail for you, please contact us to reset your password",
  // "User does not exist.": "Incorrect username or password.",
  "The quota has been exceeded.":
    "Sorry there's been an issue, please try again later",
});

const components = {
  Header() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="The Mealand Wedding logo" src={AuthLogo} height="100px" />
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 0`}
          level={3}
          textAlign="center"
        >
          The Mealand Wedding
        </Heading>
      );
    },

    Footer() {
      const { toResetPassword } = useAuthenticator();
      const { tokens } = useTheme();
      return (
        <View textAlign="center" padding={tokens.space.medium}>
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
          >
            Reset Password
          </Button>
        </View>
      );
    },
  },
};

export default function WeddingSite() {
  //const { route } = useAuthenticator((context) => [context.route]);

  //if (route === "signIn") {
  //  localStorage.clear();
  //}

  return <AfterWeddingRoutes />;

  /* return route === "authenticated" ? (
    <Routes />
  ) : (
    <div className="amplify-flex-back">
      <Flex className="amplify-flex-back" justifyContent="center">
        <Authenticator components={components}></Authenticator>
      </Flex>
    </div>
  ); */
}
