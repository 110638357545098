import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Amplify from "@aws-amplify/core";
import "assets/scss/material-kit-react.scss?v=1.10.0";
import WeddingSite from "WeddingSite.js";
import { AmplifyProvider, Authenticator } from "@aws-amplify/ui-react";
import { ErrorBoundary } from "react-error-boundary";
import awsExports from "./aws-exports";
import SomethingWentWrong from "./views/SomethingWentWrongPage/SomethingWentWrongPage.js";
Amplify.configure({
  ...awsExports,
  Analytics: {
    disabled: true,
  },
});

const ErrorFallback = ({ error }) => {
  window.stop();
  return <SomethingWentWrong error={error.message} />;
};

const rootElement = document.getElementById("root");
ReactDOM.render(
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <Router>
      <AmplifyProvider>
        <Authenticator.Provider>
          <WeddingSite />
        </Authenticator.Provider>
      </AmplifyProvider>
    </Router>
  </ErrorBoundary>,
  rootElement
);
