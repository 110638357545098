import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
/*import AccommodationPage from "views/AccommodationPage/AccommodationPage.js";
import DetailsPage from "views/DetailsPage/DetailsPage.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import MeetTheWeddingPartyPage from "views/MeetTheWeddingPartyPage/MeetTheWeddingPartyPage.js";
import OurStoryPage from "views/OurStoryPage/OurStoryPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import ThingsToDoPage from "views/ThingsToDoPage/ThingsToDoPage.js";
import TimelinePage from "views/TimelinePage/TimelinePage.js";
import VenuePage from "views/VenuePage/VenuePage.js";
import WhereToEatPage from "views/WhereToEatPage/WhereToEatPage.js";
import ContactUsPage from "views/ContactUsPage/ContactUsPage.js";
import JourneyPage from "views/JourneyPage/JourneyPage";*/
import ScrollToTop from "components/ScrollToTop/ScrollToTop.js";
import AfterWeddingPage from "views/AfterWeddingPage/AfterWeddingPage.js";
export default function AfterWeddingRoutes() {
  return (
    <ScrollToTop>
      <Switch>
        <Route exact path="/thankyou">
          <AfterWeddingPage />
        </Route>
        <Route exact path="/">
          <AfterWeddingPage />
        </Route>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </ScrollToTop>
  );
}
