import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
//import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
//import YoutubeEmbed from "components/YoutubeEmbed/YoutubeEmbed.js";
// Sections for this page
import parallaxPhoto from "assets/img/parallaxPhotos/NA-269.jpg";
//import { useAuthenticator } from "@aws-amplify/ui-react";
import PageHeaderAfterWedding from "layouts/PageHeaderAfterWedding/PageHeaderAfterWedding.js";
const useStyles = makeStyles(styles);
import IntroCard from "components/IntroCard/IntroCard.js";

export default function LandingPage(props) {
  const classes = useStyles();
  //const { ...rest } = props;

  // const { user, signOut } = useAuthenticator((context) => [context.user]);

  return (
    <div>
      <PageHeaderAfterWedding headerText="" image={parallaxPhoto} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <IntroCard headerText="Thank You!">
          <br />

          <div style={{ textAlign: "Center" }}>
            <p className={classes.description}>Dear Friends and Family,</p>
            <p className={classes.description}>
              We wanted to take a moment to express our heartfelt thanks for
              making our wedding day so incredibly special. Sharing this
              unforgettable day with all of you was a joy beyond words. The
              laughter, the tears, and all the unforgettable moments are
              memories that will stay with us for a lifetime.
            </p>
            <p className={classes.description}>
              We've sent you a link to our wedding photos and video to relive
              those cherished moments whenever you like. Because, let's face it,
              life gets busy, and sometimes we all need a little reminder of the
              love and happiness that surrounded us on that magical day.
            </p>
            <p className={classes.description}>
              Thank you for being a part of our story and for creating memories
              that will forever hold a special place in our hearts.
            </p>
            <p className={classes.description}>All our love,</p>
            <p className={classes.description}>Mr and Mrs Mealand xxxx</p>
          </div>
        </IntroCard>
      </div>
    </div>
  );
}
